html {
  background-color: #1e1e1e;
  font-family: "Inter", sans-serif;
}

h1,
h2,
h3,
p,
a {
  color: #fcedc2;
  margin: 0;
}

.app {
  box-sizing: border-box;
  text-align: center;
  background-color: #ebc328;
  background: linear-gradient(90deg, #e19a12 0%, #eec61e 100%);
}

header {
  font-family: "Inter", sans-serif;
  padding: 64px 24px 0;
  background-image: url("/public/BG.png");
  background-size: cover;
  background-position: center;
  text-transform: uppercase;
}

header > .app-logo {
  max-width: 100%;
  margin-bottom: 48px;
}

header > h1 {
  margin-bottom: 24px;
  font-weight: 800;
  font-size: 3.4rem;
}

header > h2 {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 1rem;
}

header > .header-description {
  padding: 64px 0;
  font-weight: 500;
  font-size: 1rem;
}
main {
  background-color: rgba(30, 30, 30, 0.85);
  background-image: url("/public/BG_2.png");
  background-size: cover;
  background-position: center;
}

main > .content {
  max-width: 900px;
  margin: auto;
  padding-bottom: 98px;
}

main > .content > .mid-section {
  font-family: "Crimson Pro", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  padding: 80px 40px;
  text-align: left;
}

main > .content > .mid-section > p {
  line-height: 26px;
}

.people {
  display: flex;
  align-content: space-around;
  justify-content: space-around;
  flex-wrap: wrap;
}

.people > div {
  flex: 4 1;
  background: #544538;
  border-radius: 12px;
  margin: 0 12px 12px;
  padding: 24px;
  min-width: 200px;
  max-width: 500px;
  text-align: left;
  box-shadow: 8px 13px 20px 8px rgba(36, 33, 26, 0.5);
}

.people > div:first-child {
  flex: 5 1;
}

.people > div > h3 {
  font-family: "Crimson Pro", sans-serif;
  font-weight: 900;
  font-size: 1.5rem;
  margin-bottom: 24px;
  border-left: 4px solid;
  padding-left: 16px;
}

.people > div > p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

footer {
  padding: 40px;
  background: rgba(0, 0, 0, 0.5);
}

footer * {
  font-family: "Crimson Pro", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
}

footer > div {
  max-width: 900px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-around;
  padding-top: 16px;
  gap: 16px;
}

i {
  position: relative;
  padding-right: 8px;
}

i.fa-envelope {
  top: 2px;
}

footer a {
  text-decoration: none;
  min-width: 300px;
}

@media only screen and (max-width: 800px) {
  main > .content {
    padding-bottom: 60px;
  }

  main > .content > .mid-section {
    padding: 40px 24px;
  }
}

@media only screen and (max-width: 625px) {
  header > h1 {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 400px) {
  header > h1 {
    font-size: 1.5rem;
  }
}
